<template>
  <div>
    <div class="row m-0">
      <div class="col-12 mb-2 bold">
        {{parentMenuValue.title}}
      </div>
      <div class="col-12 pb-0 subitem align-middle" v-for="subItem in subItems" :key="subItem.value" @click="isActive(subItem.value)">
        <img v-if="subItem.icons" :src="iconSrc(subItem.icons.default)" class="icon img-fluid">
        {{subItem.title}}
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {

  name: 'Submenu',
  props: ['parentMenuValue'],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'properties'
    ]),
    propertiesArr () {
      if (this.properties) {
        const arr = [...this.properties]
        return arr.sort((a, b) => (a.id < b.id ? -1 : 1))
      }
      return []
    },
    subItems () {
      if (this.parentMenuValue && this.parentMenuValue.subItems) {
        return this.parentMenuValue.subItems.filter(si => !si.hidden)
      }
      return []
    }
  },
  created () {
    this.getProperties()
  },
  methods: {
    ...mapActions([
      'getProperties'
    ]),
    iconSrc (icon) {
      return require(`../assets/images/${icon}`)
    },
    isActive (val, move = true) {
      if (move) {
        this.$emit('activeSubMenu', val)
      }
    }
  }
}
</script>
<style scoped>
.subitem {
  cursor: pointer;
  padding-bottom: 1rem;
  font-size: 1.2vw;
  line-height: 2.4vw;
}
.subitem:hover {
  opacity: 0.8;
}
@media (min-width: 1200px) {
  .subitem {
    font-size: 16px;
    line-height: 30px;
  }
}
.icon {
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: middle;
}

</style>
